.case-study-base{
  background-color: #fff;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  font-size: 1rem;
  text-align: center;
  flex:1;
}

.case-study-text {
  flex:1;
  text-align: left;
  margin-left: 10vw;
  margin-right: 10vw;
}

.case-study-wrapper{
  display: flex;
  flex-direction: row;
}

.case-study-img{
  text-align: center;
  flex:2;
  width: 70%;
}

.case-study-img-small{
  text-align: center;
  flex:2;
  width: 35%;
  height: 20%;
}


.case-study-img-padding{
  flex:1;
}

.installation-padding{
  padding-right: 1vw;
}