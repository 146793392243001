#root{
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  overflow-y: auto;
}

.app-base {
  display: flex;
  flex: 1;
  flex-wrap: wrap;
}

.link-icon{
  height: 2vh;
  border-left: 1vw;
}

.email-icon{
  height: 1.8vh;
}

.menu-wrapper {
  display: flex;
  background: #000 !important;
  width: 100%;
}

.menu-banner {
  height: 100%;
  max-width: 100%;
  flex: 1;
}


.footer {
  padding-top: 1%;
  padding-bottom: 1%;
  background: #000 !important;
  text-align: center;
  color: #fff;
  font-size: 3vh;
  flex: 10;
}
.footer-spacer{
  flex: 1;
}
.footer-wrapper{
  display: flex;
  width: 100%;
  background: #000 !important;
}

.footer a{
  color: #fff
}

.footer a:hover{
  color: #0084ff;
}

.landing-page-base{
  background-color: #fff;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  font-size: 1.5vw;
  text-align: center;
  flex:1;
}

a{
  color: #000;
  text-decoration: none;
}

.about-base{
  background-color: #fff;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  font-size: 1.5vw;
  text-align: center;
  flex:1;
}

.about-photo{
  height: 30vw;
}
.about-base a{
  color: #000
}

.case-study-text a{
  text-decoration: underline;
}

.vertical-prompt {
  background-color: #000;
  flex:1;
  border-radius: 1vh;
  max-width: 100%;
  margin-right: 1vw;
  margin-left: 1vw;
  margin-top: 1vh;
  margin-bottom: 1vh;

}

.vertical-wrapper{
  display: flex;
}



.horizontal-spacer{
  flex:0.2;
  background-color: #fff;
}

.vertical-case-image{
  max-width: 80%;
  max-height: 60%;
}

.home-wrapper{
  display: flex;
  flex:1;
  flex-wrap: wrap;
}

.case-study-title{
  border-radius: 1vh;
  background-color: #00ade7;
  margin: 0;
  margin-bottom: 4vh;
  font-size: 2.5rem;
  color: #fff;
}

.case-study-description{
  font-size: 1.7rem;
  color: #fff;
  margin:0;
  padding-top: 1vh;
  padding-bottom: 1vh;
}


.about-wrapper{
  flex:1;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  text-align: left;
}

.about-wrapper p{
  margin: 0;
}

.about-description {
  flex:4;
  font-size: 2.3vw;
}

.about-header {
  flex:4;
}

.about-spacer {
  flex: 2;
}

#map { height: 98%;
  width: 100%;
  background-color: grey; }
#sheetAuth{
margin-top: 3px;
}

.h1000 {
  height: 25vh;
  padding: 0.7vh;
}

.w1000 {
  height: 25vh;
  padding: 0.7vh;
}

.home-button{
  background-color: #fff;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  font-size: 0.8rem;
  text-align: center;
  flex:1;
  text-decoration: underline;
  margin-left: 10vw;
  margin-right: 10vw;
}

.home-button-about{
  flex:1;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  text-align: left;
  text-decoration: underline;
}

.break {
  flex-basis: 100%;
  height: 3vh;
  background-color: #fff;
}

.embedded-iframe{
  width: 70%;
  height: 30vw;
}

